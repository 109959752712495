<div class="wrapper">
    <!-- =========================
      Header
  =========================== -->
    <header id="header" class="header header-white header-full header-full-layout2">
        <nav class="navbar navbar-expand-lg navbar-fixed-top">
            <div class="container">
                <a class="navbar-brand" href="https://jeetobiocourier.com/index.php">
                    <!-- <img src="../assets/images/logo/logo-light.png" class="logo-light" alt="logo"> -->
                    <img src="../assets/images/logo/logo-dark.png" class="logo-dark" alt="logo">
                </a>
                <!-- <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="navbar_global">
                    <span class="navbar-toggler-icon"></span>
                  </button> -->
                <div class="header__topbar d-none d-lg-block">
                    <div class="d-flex flex-wrap">
                        <ul class="contact__list list-unstyled">
                            <li>
                                <i class="icon-call"></i>
                                <div>
                                    <span>Call Us:</span><strong>+91-9999339185</strong>
                                </div>
                            </li>
                            <li>
                                <i class="icon-envelope"></i>
                                <div>
                                    <span>Email Us:</span><strong><a href="mailto:info@jeetobiocourier.com">info@jeetobiocourier.com</a></strong>
                                </div>
                            </li>

                        </ul>
                        <div class="social__icons justify-content-end">
                            <a href="https://www.facebook.com/jeetobiocourier/"><i class="fa fa-facebook"></i></a>
                            <a href="https://www.instagram.com/jeetobiocourier"><i class="fa fa-instagram"></i></a>
                            <a href="https://twitter.com/jeetobiocourier"><i class="fa fa-twitter"></i></a>
                        </div>
                    </div>
                </div>
                <!-- <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="navbar_global">
                    <span class="navbar-toggler-icon"></span>
                  </button> -->
                <!-- /.header-topbar -->
                <button class="navbar-toggler" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="navbar_global">
                     <span class="menu-lines"><span></span></span>
                </button>
            </div>
            <!-- /.container -->
            <div class="navbar__bottom  sticky-navbar">
                <div class="container">
                    <div id="navbarSupportedContent">
                        <div class="collapse navbar-collapse" id="mainNavigation" [ngbCollapse]="isCollapsed">
                            <ul class="navbar-nav">
                                <li class="nav__item">
                                    <a href="https://jeetobiocourier.com/index.php" class="nav__item-link">Home</a>
                                </li>
                                <!-- /.nav-item -->

                                <li class="nav__item">
                                    <a href="https://jeetobiocourier.com/About.php" class="nav__item-link">About Us</a>
                                </li>
                                <!-- /.nav-item -->


                                <li class="nav__item with-dropdown">
                                    <a href="#" class="dropdown-toggle nav__item-link">Services</a>
                                    <i class="fa fa-angle-right" data-toggle="dropdown"></i>
                                    <ul class="dropdown-menu wide-dropdown-menu">
                                        <li class="nav__item">
                                            <div class="row mx-0">
                                                <div class="col-sm-7 dropdown-menu-col">
                                                    <h6>Major Services</h6>
                                                    <ul class="nav flex-column">
                                                        <li class="nav__item"><a class="nav__item-link" href="https://jeetobiocourier.com/Courier-Service.php">Courier Service</a>
                                                        </li>
                                                        <!-- /.nav-item -->
                                                        <li class="nav__item"><a class="nav__item-link" href="https://jeetobiocourier.com/Emergency-Courier.php">Emergency Courier </a>
                                                        </li>
                                                        <!-- /.nav-item -->
                                                        <li class="nav__item"><a class="nav__item-link" href="https://jeetobiocourier.com/Custom-Clearance.php">Custom Clearance</a>
                                                        </li>
                                                        <!-- /.nav-item -->
                                                        <li class="nav__item"><a class="nav__item-link" href="https://jeetobiocourier.com/Ware-house.php">Ware house </a>
                                                        </li>
                                                        <!-- /.nav-item -->
                                                        <li class="nav__item"><a class="nav__item-link" href="https://jeetobiocourier.com/Packaging-Materials.php">Packaging Materials</a>
                                                        </li>
                                                        <!-- /.nav-item -->
                                                        <li class="nav__item"><a class="nav__item-link" href="#">Customised project handling</a>
                                                        </li>


                                                    </ul>
                                                </div>
                                                <!-- /.col-sm-6 -->
                                                <div class="col-sm-5 dropdown-menu-col">
                                                    <img src="../assets/images/1.jpg" alt="slide img">
                                                </div>
                                                <!-- /.col-sm-6 -->
                                            </div>
                                            <!-- /.row -->
                                        </li>
                                        <!-- /.nav-item -->
                                    </ul>
                                    <!-- /.dropdown-menu -->
                                </li>
                                <!-- /.nav-item -->

                                <li class="nav__item">
                                    <a href="https://jeetobiocourier.com/clients.php" class="nav__item-link">Our Clients</a>
                                </li>
                                <!-- /.nav-item -->





                                <li class="nav__item">
                                    <a href="https://jeetobiocourier.com/About.php" class="nav__item-link">Blogs & Updates</a>
                                </li>
                                <!-- /.nav-item -->



                                <li class="nav__item">
                                    <a href="https://jeetobiocourier.com/contact.php" class="nav__item-link">Contact Us</a>
                                </li>
                                <!-- /.nav-item -->



                                <li class="nav__item">
                                    <a href="#" class="nav__item-link">Our Branches</a>
                                </li>
                                <!-- /.nav-item -->


                                <li class="nav__item">
                                    <a href="javascript:void(0)" class="nav__item-link">Tracking</a>
                                </li>
                                <!-- /.nav-item -->



                            </ul>
                            <!-- /.navbar-nav -->
                        </div>
                    </div>
                    <!-- /.navbar-collapse -->
                    <div class="navbar-modules d-none d-lg-block">
                        <ul class="list-unstyled d-flex align-items-center modules__btns-list">

                            <li>
                                <a href="https://jeetobiocourier.com/request-quote.php" class="btn btn__primary btn__hover2 module__btn-request">
                                    <span>Request A Quote</span><i class="icon-arrow-right"></i>
                                </a>
                            </li>
                        </ul>
                        <!-- /.modules-wrapper -->
                    </div>
                    <!-- /.navbar-modules -->
                </div>
                <!-- /.container -->
            </div>
            <!-- /.navbar-bottom -->
        </nav>
        <!-- /.navabr -->
    </header>
    <!-- /.Header -->