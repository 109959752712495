import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule,  NgbNavModule} from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import { NgxSpinnerModule } from 'ngx-spinner';
import {ToastrModule} from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { TrackingModule } from './tracking/tracking.module';
import { FormsModule } from '@angular/forms';
import {NgbAccordion} from '@ng-bootstrap/ng-bootstrap';
//import { NgbAccordion} from '@ng-bootstrap/ng-bootstrap/accordion/accordion'

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,    
    FormsModule,
    AppRoutingModule,
    TrackingModule,
    NgbModule,
    NgbNavModule,
    NgxSpinnerModule,
    HttpClientModule,
    ToastrModule.forRoot(
      {  
        // positionClass:'bottom-left',  
        closeButton: true,  
        positionClass: 'toast-top-right'  
      }  
    )
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
