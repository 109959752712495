<main>
    <section id="page-title" style="background-image: url(assets/images/page-titles/14.jpg);
    background-size: cover;
    background-position: center center" class="page-title bg-overlay bg-parallax ">
        <!-- <div class="bg-img"><img src="../../assets/images/page-titles/14.jpg" alt="background"></div> -->
        <div class="container">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <h1 class="pagetitle__heading">Track & Trace</h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="index.php">Home</a></li>

                            <li class="breadcrumb-item active" aria-current="page">Track & Trace</li>
                        </ol>
                    </nav>
                </div>
                <!-- /.col-lg-12 -->
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container -->

    </section>

    <section class="section pb-0 section-components" #inputtrack>
        <div class="py-5" *ngIf="isLoaded==false">
            <div class="container">
                <div class="mb-3">
                    <small class="text-uppercase font-weight-bold">Track Your Order</small>
                </div>
                <div class="row">

                    <div class="col-lg-12 col-sm-12">
                        <div class="form-group" [ngClass]="{'focused':focus1===true}">
                            <textarea rows="8" style="border-radius: 10px;" [(ngModel)]="trackingId" placeholder="Type Tracking Id. Use comman(,) separator to track multiple orders."></textarea>
                        </div>
                        <div class="form-group text-right">
                            <button *ngIf="isLoading==false" (click)="TrackOrder()" class="btn btn-primary">Track Now</button>
                            <button *ngIf="isLoading==true" class="btn btn-primary" type="button" disabled>
                                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                Loading...
                              </button>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </section>
    <section>

        <div class="pl-5 pr-5" style="padding-left: 5rem !important;padding-right: 5rem;" *ngIf="isLoaded==true">
            <header class="card-header"> My Orders > Tracking </header>
            <div style="width: 100%;" class="pr-4 text-right">
                <button (click)="Cancel()" class="btn btn-warning mb-3 mt-3 pl-3 pr-3" style="width: unset !important;" data-abc="true"> <i class="fa fa-chevron-left"></i> Track Another Order</button>
            </div>
            <article class="card" *ngFor="let ord of orderDetails.shipmentDetails;let i=index">
                <!-- <header class="card-header"> My Orders > Tracking </header> -->
                <div class="card-body" style="padding-left: 30px !important;padding-right: 30px !important;">

                    <header class="card-header bg-primary text-white"> #{{i+1}} <b>  Tracking Id:</b> {{ord.trackingId}} | <b>Order Status:</b> {{ord.orderStatus}}</header>
                    <!-- <article class="card">
                        <div class="card-body row">
                            <div class="col"> <strong>Estimated Delivery Date:</strong> <br>{{apiRep.CustomDateFormat(orderDetails.estimatedDeliveryDate)}} </div>
                            <div class="col"> <strong>Shipping BY:</strong> <br> {{orderDetails.shippedBy}} </div>
                            <div class="col"> <strong>Status:</strong> <br> {{orderDetails.orderStatus}} </div>
                            <div class="col"> <strong>Tracking #:</strong> <br> {{orderDetails.trackingId}} </div>
                        </div>
                    </article> -->
                    <!-- [ngClass]="{'active':orderDetails.orderStatusId>=2?true:false}" -->
                    <div style="width: 100%;padding-left: 150px;padding-right: 150px;">
                        <div class="track">
                            <div class="step " [ngClass]="{'active':ord.orderStatusId>=1?true:false}"> <span class="icon"> <i class="fa fa-check"></i> </span> <span class="text">Ordered</span> </div>
                            <div class="step" [ngClass]="{'active':ord.orderStatusId>=2?true:false}"> <span class="icon"> <i class="fa fa-truck"></i> </span> <span class="text"> Shipped</span> </div>
                            <div class="step" [ngClass]="{'active':ord.orderStatusId>=3?true:false}"> <span class="icon"> <i class="fa fa-truck"></i> </span> <span class="text"> In Transit </span> </div>
                            <div class="step" [ngClass]="{'active':ord.orderStatusId>=4?true:false}"> <span class="icon"> <i class="fa fa-map-marker"></i> </span> <span class="text">Delivered</span> </div>
                        </div>
                    </div>





                </div>
                <!-- <header class="card-header">Order details</header> -->
                <ngb-accordion #acc="ngbAccordion">
                    <ngb-panel id="{{'toggle'+i}}" style="text-align: right !important;" title="More Details">
                        <ng-template ngbPanelContent>

                            <ngb-tabset [destroyOnHide]="false" class=" mt-5 pb-5 pl-5">
                                <ngb-tab style="padding-top: 20px !important" title="Shipment Details">
                                    <ng-template ngbTabContent class="mt-3 table-responsive">
                                        <table style="width: 100%;" class="table table-striped mt-4">
                                            <tbody>
                                                <tr>
                                                    <th>
                                                        Tracking Id
                                                    </th>
                                                    <td>
                                                        {{ord.trackingId}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        Pickup Date
                                                    </th>
                                                    <td>
                                                        {{apiRep.CustomDateFormat(ord.pickedUpDate)}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        From
                                                    </th>
                                                    <td>
                                                        {{ord.sourceCity}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        To
                                                    </th>
                                                    <td>
                                                        {{ord.destinationCity}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        Delivered On
                                                    </th>
                                                    <td>
                                                        {{(ord.deliveredOn==null || ord.deliveredOn.length==0) ?'Yet not delivered':apiRep.CustomDateFormat(ord.deliveredOn)}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        Recipient
                                                    </th>
                                                    <td>
                                                        {{ord.recipient}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </ng-template>
                                </ngb-tab>
                                <ngb-tab>
                                    <ng-template ngbTabTitle>Status and Scan</ng-template>
                                    <ng-template ngbTabContent class="mt-3 table-responsive">
                                        <table style="width: 100%;" class="table table-striped mt-4 shipdetails">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        Current Location
                                                    </th>
                                                    <th>
                                                        Status Details
                                                    </th>
                                                    <th>
                                                        Updated On
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of ord.statusAndScan">
                                                    <td>
                                                        {{item.currentLocation}}
                                                    </td>
                                                    <td>
                                                        {{item.statusDetails}}
                                                    </td>
                                                    <td>
                                                        {{apiRep.CustomDateFormat(item.updatedOn)}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </ng-template>
                                </ngb-tab>

                            </ngb-tabset>
                        </ng-template>
                    </ngb-panel>

                </ngb-accordion>
                <!-- <table class="table table-bordered track_tbl d-none">
                    <thead>
                        <tr>
                            <th></th>
                            <th>#</th>
                            <th>Status</th>
                            <th>Location</th>
                            <th>Date/Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="active" *ngFor="let item of orderTrackingDetails;let i=index">
                            <td class="track_dot">
                                <span class="track_line"></span>
                            </td>
                            <td>{{i+1}}</td>
                            <td>{{item.orderDispatchStatus}}</td>
                            <td>{{item.orderCurrentLocation}}</td>
                            <td>{{ apiRep.CustomDateFormat(item.orderStatusDate)}}</td>
                        </tr>

                    </tbody>
                </table>
                <div class="container text-center pt-4 pb-4 text-success">
                    Destination: {{orderDetails.destinationAddress}}
                </div>
                <div class="container text-right">
                    <button (click)="Cancel()" class="btn btn-warning mb-3 mt-3" style="width: unset !important;" data-abc="true"> <i class="fa fa-chevron-left"></i> Track Another Order</button>
                </div> -->
            </article>

        </div>
    </section>
    <!-- <app-angular-section>
    </app-angular-section> -->

    <ng-template #classic2 let-c="close" let-d="dismiss">
        <div class="modal-content bg-gradient-danger">
            <div class="modal-header">
                <h6 class="modal-title" id="modal-title-notification">Your attention is required</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
            </div>
            <div class="modal-body">
                <div class="py-3 text-center">
                    <i class="ni ni-bell-55 ni-3x"></i>
                    <h4 class="heading mt-4">You should read this!</h4>
                    <p>A small river named Duden flows by their place and supplies it with the necessary regelialia.</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-white">Ok, Got it</button>
                <button type="button" class="btn btn-link text-white ml-auto" data-dismiss="modal" (click)="c('Close click')">Close</button>
            </div>
        </div>
    </ng-template>
</main>
<!-- /.page-title -->
<!-- /.page-title -->