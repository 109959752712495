import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {NotificationserviceService} from '../services/notificationservice.service';
import {ApiRepService} from '../services/api-rep.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.css']
})
export class TrackingComponent implements OnInit {

  @ViewChild('classic2') notification:ElementRef
  closeResult: string;
  trackingId:string='';
  orderDetails:any;
  orderTrackingDetails=[];
  isLoaded:boolean=false;
  isLoading:boolean=false;
  page = 2;
  page1 = 3;
  model = {
      left: true,
      middle: false,
      right: false
  };

  focus;
  focus1;
  tabs = [1, 2, 3];
  active = 2;
  activeDelayed;
  constructor(private modalService: NgbModal,
      private notificationService:NotificationserviceService,
      private apiRep:ApiRepService) { 
          setTimeout(() => {
              this.activeDelayed = 3;
            }, 2000);
      }

  ngOnInit() {}
  open(content, type, modalDimension) {
      if (modalDimension === 'sm' && type === 'modal_mini') {
          this.modalService.open(content, { windowClass: 'modal-mini', size: 'sm', centered: true }).result.then((result) => {
              this.closeResult = `Closed with: ${result}`;
          }, (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });
      } else if (modalDimension === '' && type === 'Notification') {
        this.modalService.open(content, { windowClass: 'modal-danger', centered: true }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      } else {
          this.modalService.open(content,{ centered: true }).result.then((result) => {
              this.closeResult = `Closed with: ${result}`;
          }, (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });
      }
  }
  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return  `with: ${reason}`;
      }
  }
  Close(){
      this.modalService.dismissAll()
  }
  Scroll(el: HTMLElement){
      el.scrollIntoView({behavior: 'smooth'});
    
  }
  TrackOrder(){
      this.isLoading=true;
        let body={
          trackingIds:this.trackingId
      }
      this.apiRep.trackOrder(body).subscribe((res:any)=>{
          console.log(res);
          this.orderDetails=res;
          this.isLoading=false;
          // if(res.orderDetails.length==0){
          //     // console.log(this.notification.nativeElement)
          //     this.notificationService.showError("Message","Invalid tracking Id/Order Id")
          //     return
          // }
          // this.orderDetails=res.orderDetails[0];
          // this.orderTrackingDetails=res.orderTrackingDetails;
          this.isLoaded=true;
          // console.log(this.orderTrackingDetails)
      },err=>{
          this.isLoading=false;
          console.log(err)
      })
  }
  orderStatus(){

  }
  Cancel(){
      this.isLoading=false;
      this.isLoaded=false;
  }
  navChanged1(event) {
      console.log('navChanged1', event);
    }
  
    navChanged2(event) {
      console.log('navChanged2', event);
    }
  
    navChanged3(event) {
      console.log('navChanged3', event);
    }

}
