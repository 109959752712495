<footer id="footer" class="footer">

    <div class="footer-top">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-4 footer__widget footer__widget-about">
                    <div class="footer__widget-content">
                        <img src="assets/images/logo/logo-small.png" alt="logo" class="footer-logo">
                        <p style="color: #9b9b9b !important;">JEETO Biocourier use latest technologies and concepts to provide high touch time oriented delivery and warehouse management. We specialize in last mile delivery of Bioscience and Pharmaceutical shipment.</p>
                        <ul class="contact__list list-unstyled">
                            <li><span>Address:</span><span>RZA-174/B, Gali No. 11 Road No. 4
                               Mahipalpur, New Delhi-37
                            </span>
                            </li>
                            <li><span>Phone:</span><span>+91-9999339185, +91-7987388697</span></li>
                        </ul>
                    </div>
                </div>
                <!-- /.col-lg-4 -->
                <div class="col-6 col-sm-6 col-md-3 col-lg-2 footer__widget footer__widget-nav">
                    <h6 class="footer__widget-title">Services</h6>
                    <div class="footer__widget-content">
                        <nav>
                            <ul class="list-unstyled">
                                <li><a href="#">Courier Service</a></li>
                                <li><a href="#">Emergency Courier</a></li>
                                <li><a href="#">Custom Clearance</a></li>
                                <li><a href="#">Ware house</a></li>
                                <li><a href="#">Packaging Materials</a></li>

                            </ul>
                        </nav>
                    </div>
                    <!-- /.footer-widget-content -->
                </div>
                <!-- /.col-lg-2 -->
                <div class="col-6 col-sm-6 col-md-3  footer__widget footer__widget-nav">
                    <h6 class="footer__widget-title">What We Transport</h6>
                    <div class="footer__widget-content">
                        <nav>
                            <ul class="list-unstyled">
                                <li><a href="#">Bio-Products such as Blood Samples, Tissues and Cells</a></li>
                                <li><a href="#">Bio-Pharmaceuticals (Proteins and DNA)</a></li>
                                <li><a href="#">Live Animals and Antibodies</a></li>
                                <li><a href="#">Life Saving Drugs</a></li>
                                <li><a href="#">Transgenic plant seed and GMO</a></li>
                                <li><a href="#">Perishable Plant Sample</a></li>
                            </ul>
                        </nav>
                    </div>
                    <!-- /.footer-widget-content -->
                </div>
                <!-- /.col-lg-2 -->

                <div class="col-6 col-sm-6 col-md-3 col-lg-2 footer__widget footer__widget-nav">
                    <h6 class="footer__widget-title">Quick Links</h6>
                    <div class="footer__widget-content">
                        <nav>
                            <ul class="list-unstyled">
                                <li><a href="#">Feedback Form</a></li>
                                <li><a href="#">Track & Trace</a></li>
                                <li><a href="#">Find A Location</a></li>
                                <li><a href="#">Global Agents</a></li>
                                <li><a href="#">Help & FAQ</a></li>
                            </ul>
                        </nav>
                    </div>
                    <!-- /.footer-widget-content -->
                </div>
                <!-- /.col-lg-2 -->
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container -->
    </div>
    <!-- /.footer-top -->
    <div class="footer-bottom" style="background-color: #1d1d2d;">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <div class="footer__copyright">
                        <span>&copy; 2021 Jeeto BIOCOURIER</span>

                    </div>
                    <!-- /.Footer-copyright -->
                </div>
                <!-- /.col-lg-6 -->
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <nav>
                        <ul class="list-unstyled footer__copyright-links d-flex flex-wrap justify-content-end">
                            <li><a href="#">Terms & Conditions </a></li>
                            <li><a href="#">Privacy Policy</a></li>
                            <li><a href="#">Sitemap</a></li>
                        </ul>
                    </nav>
                </div>
                <!-- /.col-lg-6 -->
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container -->
    </div>
    <!-- /.Footer-bottom -->

</footer>