import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { 
    path: 'tracking', 
  loadChildren: () => import('./tracking/tracking.module').then(m => m.TrackingModule) 
},{ 
  path: 'tr', 
loadChildren: () => import('./tracking/tracking.module').then(m => m.TrackingModule) 
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
