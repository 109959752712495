import { Component, Input } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'jeetobio';
  public isCollapsed = true;
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  @Input() name: string;

  isOpen = false;
  isDropdownOpen = false;
  isShown:boolean=false;
  constructor(public router:Router) { 
    this.router.navigateByUrl('/')
  }

  ngOnInit() {
    this.getData();
  }

  toggleNavbar() {
    this.isOpen = !this.isOpen;
  }

  toggleDropDown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  getData() {
    // this.http.get('https://jsonplaceholder.typicode.com/posts').map(res => res.json()).subscribe(res => {
    //   console.log(res);
    // });
  }
  
}
