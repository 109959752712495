import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TrackingRoutingModule } from './tracking-routing.module';
import { TrackingComponent } from './tracking.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [TrackingComponent],
  exports:[ TrackingRoutingModule ],
  imports: [
    // CommonModule,
    // FormsModule,
    // TrackingRoutingModule
    CommonModule,
    FormsModule,
    RouterModule,NgbModule
  ]
})
export class TrackingModule { }
