import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { resolve } from '@angular/compiler-cli/src/ngtsc/file_system';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ApiRepService {

 baseUrl=environment.baseUrl;
 // baseUrl = "https://enc-soft-solutions.com/jeetobio/v1/"
  monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  httpErrorMessage = "Something went wrong!"
  httpErrorTitle = "Error";
  allOrdersList = [];
  ordersStatus = [];
  userDetails: any;
  loginStatus: boolean = false;
  constructor(public http: HttpClient) { }

  getOrderStatus() {
    return new Promise((resolve, reject) => {
      this.orderStatus().subscribe((res: any) => {
        this.ordersStatus = res;
        resolve(res)
      }, (err) => {
        reject(err)
      })
    })
  }

  CustomDateFormat(dt) {
    const d = new Date(dt);
    let month = this.monthNames[d.getMonth()];
    let date = d.getDate();
    let year = d.getFullYear();
    return month + " " + date + ", " + year;
  }
  myProfile() {
    return new Promise((resolve, reject) => {
      this.me().subscribe((res: any) => {
        this.userDetails = res;
        this.loginStatus = true;
        resolve(res);

      }, (err) => {
        reject(err);
      })
    })
  }
  GetToken() {
    return localStorage.getItem('token');
  }
  GetHeader() {
    console.log(localStorage.getItem('token'))
    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': this.GetToken(),
        "content-type": "application/json"
      })
    };
    return httpOptions
  }
  trackOrder(body) {
    return this.http.post(this.baseUrl + "track",  body,this.GetHeader())
  }
  login(body) {
    return this.http.post(this.baseUrl + "auth/login", body);
  }
  me() {
    return this.http.get(this.baseUrl + "auth/me", this.GetHeader());
  }
  allOrders() {
    return this.http.get(this.baseUrl + "order/orders", this.GetHeader());
  }
  orderStatus() {
    return this.http.get(this.baseUrl + "order/status")
  }
  addneworder(body) {
    return this.http.post(this.baseUrl + "order/addorder", body, this.GetHeader())
  }

}
